const GettingStarted = () => {
    return (  
      <>
        <h3 className="section__title">Get Started</h3>
        <p>This document will explain the steps to get internet data and airtime at affordable prices. If you are new to DataLab you need to register, it takes just 2 minutes.</p>
        {/* <h3 className="section__title">Flow</h3>
        <div className="code__block code__block--notabs">
          <pre className="code code--block">
            <code>
                  Register on DataLab (new users only) <br />
                  Verify Your Email (new users only) <br />
                  Select Network <br />
                  Select Data or Aritime Plan, <br />                    
                  Enter Phone number <br />
                  Login <br />
                  Proceed <br />
                  Recieve Data or Airtime <br />
            </code>
          </pre>
        </div> */}
        <hr />
    </>
    );
}

export default GettingStarted;