import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import  './scribbler-doc.css';
import Registration from "./Registration";
import GettingStarted from "./GettingStarted";
import Login from "./Login";
import BuyData from "./BuyData";
import BuyAirtime from "./BuyAirtime";
import FundWallet from "./FundWallet";
import TransactionHistory from "./TransactionHistory";
import Nav from "../nav/Nav";

var getAll = function (selector, scope) {
    scope = scope ? scope : document;
    return scope.querySelectorAll(selector);
  };

  const styles = {
    wrapper: {
        display: 'flex',
        flexGrow: 1
      }
  }

const Docs = () => {
    const [mode, setMode] = useState(window.screen.width >= 768 ? 'pc' : 'mobile');

    useEffect(()=>{
        //in page scrolling for documentaiton page
        var btns = getAll('.js-btn');
        var sections = getAll('.js-section');

        function setActiveLink(event) {
        // remove all active tab classes
        for (var i = 0; i < btns.length; i++) {
            btns[i].classList.remove('selected');
        }

        event.target.classList.add('selected');
        }

        function smoothScrollTo(i, event) {
        var element = sections[i];
        setActiveLink(event);

        window.scrollTo({
            'behavior': 'smooth',
            'top': element.offsetTop - 20,
            'left': 0
        });
        }

        if (btns.length && sections.length > 0) {
        for (var i = 0; i<btns.length; i++) {
            btns[i].addEventListener('click', smoothScrollTo.bind(this,i));
        }
        }
    },[]);

    return (
        <>
          <div className="doc__bg"></div>
          {/* <nav className="header">
            <h1 className="logo" style={{gridColumn: 2}}>Datalab <span className="logo__thin">Doc</span></h1>
            <ul className="menu">
              <div className="menu__item toggle"><span></span></div>
              <li className="menu__item"><Link to="/" className="link link--dark"><i className="fa fa-home"></i> Home</Link></li>
              <li className="menu__item"><Link to="/docs" className="link link--dark"><i className="fa fa-book"></i> Documentation</Link></li>
            </ul>
          </nav> */}
          
          <Nav />

          <div className="wrapper" style={styles.wrapper}>

            <aside className="doc__nav">
              <ul>
                <li className="js-btn selected">Get Started</li>
                <li className="js-btn">Registration</li>
                <li className="js-btn">Login</li>
                <li className="js-btn">Fund Wallet</li>
                <li className="js-btn">Buy Data</li>
                <li className="js-btn">Buy Airtime</li>
                <li className="js-btn">View Transaction History</li>
              </ul>
            </aside>

            <article className="doc__content">  
              <section className="js-section">
                <GettingStarted />
              </section>              

              <section className="js-section">
                <Tabs
                  defaultActiveKey="pc"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(e)=> setMode(e)}
                >            
                  <Tab eventKey="pc" title="I'm Using PC" >                    
                    <Registration mode={mode} />
                  </Tab>
                  <Tab eventKey="mobile" title="I'm using Mobile Phone">
                    <Registration mode={mode} />
                  </Tab>
                </Tabs>                
              </section>

              <section className="js-section">
                  <Login mode={mode} />                
              </section>
              <section className="js-section">
                  <FundWallet mode={mode} />
              </section>
              <section className="js-section">
                  <BuyData mode={mode} />
              </section>
              <section className="js-section">
                <BuyAirtime mode={mode} />
                <hr />
              </section>


              <section className="js-section">
                <TransactionHistory mode={mode} />
              </section>

            </article>

          </div>
        </>
    );
}

export default Docs;