import { useEffect } from "react";
// import {Link} from 'react-router-dom';

const Nav = () => {

    useEffect(()=>{
        var topNav = document.querySelector('.menu');
        var icon = document.querySelector('.toggle'); 

        window.addEventListener('load', function(){
            function showNav(e) {
                // let counter2 = 1;

                topNav.className = 'menu';
                icon.classList.remove('open'); 

 
                //console.log('in', counter2, 'ok');
                if (topNav.className === 'menu') {
                    //console.log('yes');
                    topNav.className += ' responsive';
                    icon.className += ' open';
                } else {
                   // console.log('no')
                    topNav.className = 'menu';
                    icon.classList.remove('open'); 
                }                                        
                                
              e.preventDefault();
            }
            
            icon.addEventListener('click', showNav);
          });
    });

    return (
        <>
            <div className="row bg-light text-center">
                <div className="logo">
                    <img src="/favicon.ico" alt="" className="img-fluid" /> User Guide
                </div>
            </div>
            {/* <nav>
                <div></div>
                <ul className="menu ">
                    <div className="menu__item toggle"><span></span></div>
                    <li className="menu__item">
                        <Link to="/" className="link link--dark"><i className="fa fa-home"></i> Home</Link>
                    </li>
                    <li className="menu__item">
                        <Link to="/demo" className="link link--dark"><i className="fa fa-book"></i> Documentation</Link>
                    </li>
                </ul>
            </nav> */}
        </>
    );
}

export default Nav;