const BuyData = ({mode}) => {
    return (  
        <>
            <h3 className="section__title">Buy Data</h3>
            <p>Find below the steps to quickly Buy Data from {process.env.REACT_APP_PARENT_APP_NAME}</p>
            <ul>
                <li>
                    Visit
                    <strong>
                    &nbsp;<a href="https://data.greatftech.com/" title="Click to visit page" target="_blank" rel="noreferrer noopener" >{process.env.REACT_APP_PARENT_APP_NAME}</a>
                    </strong>                     
                </li>

                {
                    mode === 'mobile' ?
                    <li>
                    Click on the <strong>Button</strong> at the top right corner and then click on <strong>data</strong>
                    <div>
                        <img src={`/assets/images/datalab_home_page_mobile.jpeg`} className="img-fluid" alt=""/>
                    </div>
                </li>
                :
                    <li>
                        Click on <strong>Data</strong> at the top
                        <div>
                            <img src={`/assets/images/${mode === 'mobile' ? 'data_link_mobile.jpeg' : 'data_link.png'}`} className="img-fluid" alt=""/>
                        </div>                    
                    </li>
                }

                <li>
                    Select  <strong>Network, Data Plan and enter the phone number</strong> and click on <strong>Send Data</strong>. 
                    <br />
                    <strong>Note</strong>: If you get "This number does not look like MTN number. You can continue if you're sure", uncheck the validate this phone number checkbox
                    <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'data_form_mobile.jpeg' : 'data_form.png'}`} className="img-fluid" alt=""/>                    
                    </div>                    
                </li>

                <li>
                    <strong>If you are not logged in already, the login modal will pop up,</strong> Enter your email and password and click on <strong>Login</strong>
                    <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'login_modal_mobile.jpeg' : 'login_modal.png'}`} className="img-fluid" alt=""/>
                    </div>                    
                </li>
            
                <li>
                    After Login, Click on <strong>Send Data</strong> you'll get an alert that the data has been transfered. Click on <strong>Ok</strong> to view receipt
                    <br />
                    <strong>Note:</strong> It usually takes less than 1 minutes for the data to enter your phone. If you didn't receive the data after 5 minutes kindly contact us on Whatsapp
                    <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'data_transfer_alert_mobile.jpeg' : 'data_transfer_alert.png'}`} className="img-fluid" alt=""/>
                        <img src={`/assets/images/${mode === 'mobile' ? 'data_receipt_mobile.jpeg' : 'data_receipt.png'}`} className="img-fluid" alt=""/>
                    </div>                    
                </li>

            </ul>
            <hr />
        </>
    );
}

export default BuyData;