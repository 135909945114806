const TransactionHistory = ({mode}) => {
    return (  
        <>
            <h3 className="section__title">How to View Transaction History</h3>
            <p>Find below the steps to quickly view your transaction history on {process.env.REACT_APP_PARENT_APP_NAME}</p>
            <ul>
                <li>
                    Visit
                    <strong>
                    &nbsp;<a href="https://data.greatftech.com/" title="Click to visit page" target="_blank" rel="noreferrer noopener" >{process.env.REACT_APP_PARENT_APP_NAME}</a>
                    </strong>                     
                </li>

                <li>
                    Make sure you are already logged in
                    <br />
                    <strong>Note</strong>: If you don't know how to login yet, check out the instructions on how to login                  
                </li>

                {
                    mode === 'mobile' ?
                    <li>
                    Click on the <strong>Button</strong> at the top right corner and then click on <strong>your name</strong> and click on <strong>Wallet History</strong>
                    <div>
                        <img src={`/assets/images/datalab_home_page_mobile.jpeg`} className="img-fluid" alt="missing" />
                        <img src={`/assets/images/wallet_history_link_mobile.jpeg`} className="img-fluid" alt="missing" />
                    </div>
                </li>
                :
                    <li>
                        Click on <strong>your name</strong> at the top and then click on <strong>Wallet History</strong>
                        <div>
                            <img src={`/assets/images/${mode === 'mobile' ? 'wallet_history_link_mobile.jpeg' : 'wallet_history_link.png'}`} className="img-fluid" alt="" />
                        </div>                    
                    </li>
                }
                
                <li>
                    You'll see the list of your transaction.
                    <strong>Note:</strong> You can use the pagination the records
                    <div>
                        <img src={`/assets/images/wallet_history`} className="img-fluid" alt="" />                    
                    </div>                    
                </li>

            </ul>
            <hr />
        </>
    );
}

export default TransactionHistory;