const FundWallet = ({mode}) => {
    return (  
        <>
            <h3 className="section__title">How to Fund Wallet</h3>
            <p>Find below the steps to quickly fund your wallet on {process.env.REACT_APP_PARENT_APP_NAME}</p>
            <ul>
                <li>
                    Visit
                    <strong>
                    &nbsp;<a href="https://data.greatftech.com/" title="Click to visit page" target="_blank" rel="noreferrer noopener" >{process.env.REACT_APP_PARENT_APP_NAME}</a>
                    </strong>                     
                </li>

                <li>
                    Make sure you are already logged in
                    <br />
                    <strong>Note</strong>: If you don't know how to login yet, check out the instructions on how to login                  
                </li>

                {
                    mode === 'mobile' ?
                    <li>
                    Click on the <strong>Button</strong> at the top right corner and then click on <strong>your name</strong> and click on <strong>Fund Wallet</strong>
                    <div>
                        <img src={`/assets/images/datalab_home_page_mobile.jpeg`} className="img-fluid" alt=""/>
                    </div>
                </li>
                :
                    <li>
                        Click on <strong>Fund Wallet</strong> at the top
                        <div>
                            <img src={`/assets/images/${mode === 'mobile' ? 'fund_wallet_link_mobile.jpeg' : 'fund_wallet_link.png'}`} className="img-fluid" alt=""/>
                        </div>                    
                    </li>
                }
                
                <li>
                    Enter the  <strong>Amount</strong> and click on <strong>Proceed</strong>. 
                    <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'wallet_funding_form_mobile.jpeg' : 'wallet_funding_form.png'}`} className="img-fluid" alt=""/>                    
                    </div>                    
                </li>

                <li>
                    Select any payment method of your choice and follow the instructions to fund your wallet
                    <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'payment_options_mobile.jpeg' : 'payment_options.png'}`} className="img-fluid" alt=""/>                    
                    </div>                    

                </li>
                <li>
                    After payment your receipt will display and your wallet will be credited automatically
                    <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'successful_wallet_funding_mobile.jpeg' : 'successful_wallet_funding.png'}`} className="img-fluid" alt=""/>
                    </div>                  
                </li>
            
                <li>
                    To view your wallet balance logout and login again, then <strong>Click on your name</strong> at the top
                    <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'wallet_balance_mobile.jpeg' : 'wallet_balance.png'}`} className="img-fluid" alt=""/>
                    </div>                    
                </li>

            </ul>
            <hr />
        </>
    );
}

export default FundWallet;