import { useEffect } from "react";
import Nav from "../nav/Nav";

const Demo = () => {
    let counter = 1;
    useEffect(()=>{
        // setup typewriter effect in the terminal demo
        if (document.getElementsByClassName('demo').length > 0 && counter === 1) {
            var i = 0;
            counter++;
            var txt = `Datalab
                    [Entry mode; press Ctrl+D to save and quit; press Ctrl+C to quit without saving]
        
                    ###todo for new year dinner party

                    - milk
                    - butter 
                    - green onion 
                    - lots and lots of kiwis 🥝`;
            var speed = 60;
        
            function typeItOut () {
            if (i < txt.length) {
                document.getElementsByClassName('demo')[0].innerHTML += txt.charAt(i);
                i++;
                setTimeout(typeItOut, speed);
            }
            }
        
            setTimeout(typeItOut, 1800);
        }
    });

    return (
        <>        
            <Nav />

            <div className="hero">
            <h1 className="hero__title">datalab</h1>
            <p className="hero__description">Take your markdown notes in terminal</p>
            </div>
            <div className="hero__terminal">
            <pre>
                {/* Place your demo code here */}
                <code className="shell-session demo">hyperyolo ~ $ </code>
            </pre>
            </div>
            <div className="wrapper">
            <div className="installation">
                <h3 className="section__title">Installation</h3>
                <div className="tab__container">
                <ul className="tab__menu">
                    <li className="tab active" data-tab="mac">mac</li>
                    <li className="tab" data-tab="linux">linux</li>
                    <li className="tab" data-tab="win">win</li>
                </ul>
                <pre className="nohighlight code">
                    <code className="tab__pane active mac">$  brew install Datalab</code>
                    <code className="tab__pane linux">$  apt-get install Datalab</code>
                    <code className="tab__pane win">$  gem install Datalab</code>
                </pre>
                </div>
            </div>
            <div className="feature">
                <div className="feature__item">
                <h3 className="section__title">Fast & Light</h3>
                <p>Start writing your notes immediately in any terminal! No more time wasted on navigating and opening your text editor.</p>
                </div>
                <div className="feature__item">
                <h3 className="section__title">File Syncing</h3>
                <p>Save your file in Dropbox then you can access to it from anywhere.</p>
                </div>
                <div className="feature__item">
                <h3 className="section__title">Secure</h3>
                <p>Encrypt your notes optionally. No one can get to your secrets! </p>
                </div>
                <div className="feature__item">
                <h3 className="section__title">Configuration</h3>
                <p>Maintain all your settings in a single <span className="code code--inline">config.json</span> file. Never need to redo the setting every single time jotting down a note.</p>
                </div>
                <div className="feature__item">
                <h3 className="section__title">Highlightings</h3>
                <p>For better readability, Datalab has a clean, beautiful color scheme allow you to scan files fast.</p>
                </div>
                <div className="feature__item">
                <h3 className="section__title">Keybindings</h3>
                <p>You can expect common keybindings for datalab. Customize <span className="code code--inline">bindings.json</span> for your own liking! </p>
                </div>
            </div>
            <div className="keybinding">
                <ul className="keybinding__detail">
                <h3 className="keybinding__title">Default Keybindings</h3>
                <li>Quit without saving <span className="keybinding__label">Ctrl+C</span></li>
                <li>Save <span className="keybinding__label">Cmd+S</span></li>
                <li>Save and Quit <span className="keybinding__label">Ctrl+D</span></li>
                <li>Undo <span className="keybinding__label">Cmd+Z</span></li>
                </ul>
                <ul className="keybinding__detail">
                <h3 className="keybinding__title">Markdown Keybindings</h3>
                <li><span className="keybinding__label">Ctrl+A</span> Insert Link Markdown</li>
                <li><span className="keybinding__label">Ctrl+I</span> Insert Image Markdown</li>
                <li><span className="keybinding__label">Ctrl+V</span> Insert YouTube Video</li>
                <li><span className="keybinding__label">Ctrl+T</span> Insert Table</li>
                </ul>
            </div>
            <div className="callout">
                <p>Read our documentation for advanced keybindings and customization</p>
                <a href="doc.html" className="button--primary">Documentation</a>
            </div>
            </div>
            <div className="changelog">
            <div className="wrapper">
                <h3 className="section__title">Changelog</h3>
                <div className="changelog__item">
                <div className="changelog__meta">
                    <h4 className="changelog__title">v0.7</h4>
                    <small className="changelog__date">10/12/2017</small>
                </div>
                <div className="changelog__detail">
                    <ul>
                    <li>Improving the writing workflow with better key bindings</li>
                    <li>Design updates</li>
                    <li>SSL Verification for web hooks</li>
                    <li>Render Emoji</li>
                    </ul>
                </div>
                </div>
                <div className="changelog__item">
                <div className="changelog__meta">
                    <h4 className="changelog__title">v0.6</h4>
                    <small className="changelog__date">7/30/2017</small>
                </div>
                <div className="changelog__detail">
                    <ul>
                    <li>Adding Unicode support</li>
                    <li>Basic text highlighting</li>
                    <li>Fresh Design</li>
                    </ul>
                </div>
                </div>
                <div className="changelog__item">
                <div className="changelog__meta">
                    <h4 className="changelog__title">v0.5</h4>
                    <small className="changelog__date">5/10/2017</small>
                </div>
                <div className="changelog__detail">
                    <ul>
                    <li>Save default md file in new folders</li>
                    <li>Ability to quick search on existing notes</li>
                    </ul>
                </div>
                </div>
                <div className="changelog__callout">
                <a href="https://data.greatftech.com" className="button--secondary">Checkout Full Log</a>
                </div>
            </div>
            </div>

        </>
    )
}

export default Demo