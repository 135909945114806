const Login = ({mode}) => {
    return (  
        <>
            <h3 className="section__title">Login</h3>
            <p>Find below the steps to quickly login to {process.env.REACT_APP_PARENT_APP_NAME}</p>
            <ul>
                <li>
                    Visit
                    <strong>
                    &nbsp;<a href="https://data.greatftech.com/" title="Click to visit page" target="_blank" rel="noreferrer noopener" >{process.env.REACT_APP_PARENT_APP_NAME}</a>
                    </strong>                     
                </li>
                {
                    mode === 'mobile' ?
                    <li>
                    Click on the <strong>Button</strong> at the top right corner and then click on <strong>Login</strong>
                    <div>
                        <img src={`/assets/images/datalab_home_page_mobile.jpeg`} className="img-fluid" alt=""/>
                    </div>
                </li>
                :
                    <li>
                        Click on <strong>Login</strong>
                        <div>
                            <img src={`/assets/images/${mode === 'mobile' ? 'login_button_mobile.jpeg' : 'login_button.png'}`} className="img-fluid" alt=""/>
                        </div>
                    </li>
                }

                <li>
                    Click on <strong>Login with Facebook</strong> or Enter the <strong>Email and Password</strong> you used to register and click on <strong>Login</strong>
                    <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'login_form_mobile.jpeg' : 'login_form.png'}`} className="img-fluid" alt=""/>
                    </div>
                </li>

                <li>
                    The system will authenticate you. You should be able to see your name and your referral link at the top
                    <div>
                    <img src={`/assets/images/${mode === 'mobile' ? 'successful_login_mobile.jpeg' : 'successful_login.png' } `} className="img-fluid" alt=""/>
                    </div>
                </li>
            </ul>
            <hr />
        </>
    );
}

export default Login;