import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './App.css';
import Docs from "./components/docs/";
import Demo from './components/demo/Demo';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Docs />    
  },
  {
    path: "demo",
    element: <Demo />,
  },
]);

function App() {
  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  );
}

export default App;
