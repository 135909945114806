import Accordion from 'react-bootstrap/Accordion';

const Registration = ({mode}) => {
    return (
        <>          
            <h3 className="section__title">Registration</h3>
            <p>Find below the steps to quickly register on {process.env.REACT_APP_PARENT_APP_NAME}. Please note that you can either register with your facebook account or by filing the registration form. </p>                  

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Facebook Registration</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Visit
                      <strong>
                        &nbsp;<a href="https://data.greatftech.com/register" title="Click to visit page" target="_blank" rel="noreferrer noopener" >{process.env.REACT_APP_PARENT_APP_NAME}  Registration page</a>
                      </strong>                     
                    </li>
                    <li>
                      Click on <strong>Register with facebook</strong> at the top of the page
                      <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'facebook_button_mobile.jpeg' : 'facebook_button.png'}`} className="img-fluid" alt=""/>
                      </div>
                    </li>

                    <li>
                      The System will create your account and log you in automatically. To confirm this, you should see your name and your referral link at the top
                      <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'successful_login_mobile.jpeg' : 'successful_login.png' } `} className="img-fluid" alt=""/>
                      </div>
                    </li>
                  </ul>

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Form Registration</Accordion.Header>
                <Accordion.Body>
                <ul>
                    <li>
                      Visit
                      <strong>
                        &nbsp;<a href="https://data.greatftech.com/register" title="Click to visit page" target="_blank" rel="noreferrer noopener" >{process.env.REACT_APP_PARENT_APP_NAME} Registration page</a>
                      </strong>                     
                    </li>
                    <li>
                      Enter your <strong>Full Name, Email, Phone number, Password and the same password again</strong> at the bottom of the page. Then click on <strong>Create my Account</strong> button
                      <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'form_reg_mobile.jpeg' : 'form_reg.png'}`} className="img-fluid" alt=""/>
                      </div>
                    </li>

                    <li>
                      The system will send a verification email to you.
                      <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'email_notice_mobile.jpeg' : 'email_notice.png' } `} className="img-fluid" alt=""/>
                      </div>
                    </li>
 
                    <li>
                      Login to your email and click on the email from {process.env.REACT_APP_COMPNAY_NAME}, then click on the verification link in the email
                      <div>
                        <img src={`/assets/images/reg_email.png`} className="img-fluid" alt=""/>
                      </div>
                    </li>
 
                    <li>
                      The system will verify your email and log you into {process.env.REACT_APP_PARENT_APP_NAME}. You should be able to see your name and your referral link at the top
                      <div>
                        <img src={`/assets/images/${mode === 'mobile' ? 'successful_login_mobile.jpeg' : 'successful_login.png' } `} className="img-fluid" alt=""/>
                      </div>
                    </li>

                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <hr />
        </>
    );
}

export default Registration;